import React, { useRef } from 'react';

import './index.css';
import { useCompleteOnScreen } from '../../../utils/hooks';
import { AnimatedDots } from '../../Dots';
import DesktopView from './DesktopView';
import MobileView from './MobileView';

const avalailableCategories = [
  'Leadership',
  'Operations',
  'Business Analysis',
  'Design',
  'Development'
];

const OurTeam = ({ items }) => {
  const ref = useRef();
  const isCompleteOnScreen = useCompleteOnScreen(ref);

  return (
    <section ref={ref} className="people-our-team">
      <div className="heading secondary-padding">
        <p className="title">Meet The Team</p>
        <AnimatedDots completeOnScreen={isCompleteOnScreen} />
      </div>
      <div className="desktop-view">
        <DesktopView
          items={items}
          avalailableCategories={avalailableCategories}
        />
      </div>
      <div className="mobile-view">
        <MobileView
          items={items}
          avalailableCategories={avalailableCategories}
        />
      </div>
    </section>
  );
};

export default OurTeam;
