import React, { useEffect, useState } from 'react';

import './index.css';
import TeamList from '../TeamList';
import { isOdd } from '../../../../utils';

const MobileView = ({ items, avalailableCategories }) => {
  const [categoriesToShow, setCategoriesToShow] = useState([]);

  useEffect(() => {
    const categoriesToShow = [];
    avalailableCategories.forEach((category) => {
      const showCat = items.find((item) => item.category === category);
      if (showCat) categoriesToShow.push(category);
    });
    setCategoriesToShow(categoriesToShow);
  }, []);

  const setCategoryQuantity = (category) => {
    let quantity = 0;
    items.forEach((item) => {
      if (item.category === category) quantity += 1;
    });
    return category.concat(` (${quantity})`);
  };

  const filterByCategory = (category) => {
    return items.filter((item) => item.category === category);
  };

  return (
    <div className="our-team-mobile-view">
      {categoriesToShow.map((category, index) => (
        <div
          key={index}
          className={`category ${isOdd(index) ? 'gray-background' : ''}`}
        >
          <p className="category-title">{setCategoryQuantity(category)}</p>
          <TeamList
            items={filterByCategory(category)}
            shiftCards={isOdd(index)}
          />
        </div>
      ))}
    </div>
  );
};

export default MobileView;
