import React, { useState } from 'react';

import Img from 'gatsby-image';

import './index.css';
import linkedIn from '../../../../img/linkedin.svg';

const TeamCard = ({ item, shift }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`team-card ${shift ? 'shift' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <a href={item.linkedin} target="_blank" rel="noopener">
        <div className="team-card-wrapper">
          {isHovered ? (
            <div className="emoji-container">{item.emoji}</div>
          ) : (
            <Img
              className="team-card-img"
              fluid={item.photo.childImageSharp.fluid}
              alt={item.name}
            />
          )}
          <div className="info-container">
            <p className="name">
              {isHovered && !!item.linkedin ? (
                <>
                  Let's connect{' '}
                  <img
                    src={linkedIn}
                    alt="Linkedin"
                    className="contact-img"
                  />
                </>
              ) : (
                item.name
              )}
            </p>
            <p className="role">{item.role}</p>
          </div>
        </div>
      </a>
    </div>
  );
};

export default TeamCard;
