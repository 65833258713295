import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/AppContext';

// Sections
import Hero from '../../components/peopleSections/Hero';
import CoreValuesSection from '../../components/CoreValues';
import OurStorySection from '../../components/peopleSections/OurStorySection';
import OurTeamSection from '../../components/peopleSections/OurTeamSection';
import CareersSection from '../../components/peopleSections/CareerSection';

const pageTitle = 'Meet The People of Devetry | Devetry';
const pageDescription =
  'Meet the extraordinary team at Devetry, browse careers, and learn more about Code Forward.';

const PeoplePageTemplate = ({ data, heroImage }) => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('people');
  }, [setActivePage]);

  const teamMembersSorted = data.team_member.sort((a,b) => a.name > b.name ? 1: -1)

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <div className="root-container">
        <Hero
          title="People First"
          description="This simple philosophy influences every decision we make."
          image={heroImage}
        />
        <CoreValuesSection />
        <OurStorySection />
        <OurTeamSection items={teamMembersSorted} />
        <CareersSection />
      </div>
    </>
  );
};

const PeoplePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const heroImage = data.file;
  return <PeoplePageTemplate data={frontmatter} heroImage={heroImage}/>;
};

PeoplePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default PeoplePage;

export const pageQuery = graphql`
  query PeoplePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "People/index" } }) {
      frontmatter {
        templateKey
        team_member {
          name
          role
          emoji
          linkedin
          category
          photo {
            childImageSharp {
              fluid(maxWidth: 240) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    file(relativePath: {
      eq: "team-meeting2.jpg"
    }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 60){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
