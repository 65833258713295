import React from 'react';

import './index.css';
import TeamCard from '../TeamCard';

const TeamList = ({ items, shiftCards }) => {
  return (
    <div className="team-list secondary-padding">
      {items.map((item, index) => (
        <TeamCard key={index} item={item} shift={shiftCards} />
      ))}
    </div>
  );
};

export default TeamList;
