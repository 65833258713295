import React from 'react';

import './index.css';
import workDots from '../../../img/work-dots.svg';
import orangeEllipse from '../../../img/orange-ellipse.svg';
import Img from 'gatsby-image';

export default ({ title, description, image }) => {
  return (
    <section className="people-hero">
      <div className="left root-padding">
        <img
          className="dots"
          src={workDots}
          alt="Dots"
        />
        <img
          className="dots-bottom"
          src={workDots}
          alt="Dots"
        />
        <img
          className="orange-ellipse"
          src={orangeEllipse}
          alt="orange-ellipse"
        />
        <div className="red-dot" />
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
      <div className="right" >
        <Img fluid={image.childImageSharp.fluid} alt="Team Meeting" />
      </div>
    </section>
  );
};
