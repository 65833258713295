import React, { useRef } from 'react';

import './index.css';
import { useCompleteOnScreen } from '../../../utils/hooks';
import { AnimatedDots } from '../../Dots';
import ourStory from '../../../img/people-our-story.svg';

const OurStory = () => {
  const ref = useRef();
  const isCompleteOnScreen = useCompleteOnScreen(ref);

  return (
    <section ref={ref} className="our-story root-padding">
      <div className="left">
        <img src={ourStory} alt="our-story" className="section-img" />
      </div>
      <div className="right">
        <p className="heading">Our Story</p>
        <AnimatedDots completeOnScreen={isCompleteOnScreen} />
        <p className="description">
          In 2015, two long-time colleagues ventured out on their own to create
          Devetry, a software services company that revolves around respect,
          empowerment, and growth. Their guiding principles are 1. Create a
          strong culture where people’s voices are heard and 2. provide
          exceptional creative and technical solutions. Since then, Devetry has
          grown to be one of Denver’s largest software companies, with a second
          office in Portland, Oregon. In 2021, we were acquired by DEPT®, a
          European digital agency headquartered in Amsterdam.
        </p>
      </div>
    </section>
  );
};

export default OurStory;
