import React, { useState, useEffect } from 'react';

import './index.css';
import SelectionBar from '../../../SelectionBar';
import TeamList from '../TeamList/index';

const DesktopView = ({ items, avalailableCategories }) => {
  const [selectionBarItems, setSelectionBarItems] = useState([]);
  const [currentCat, setCurrentCat] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    const categoriesToShow = ['All'];
    avalailableCategories.forEach((category) => {
      const showCat = items.find((item) => item.category === category);
      if (showCat) categoriesToShow.push(category);
    });
    setSelectionBarItems(categoriesToShow);
  }, [avalailableCategories, items]);

  useEffect(() => {
    if (!currentCat) setCurrentItems([]);
    else filterByCategory(selectionBarItems[currentCat]);
  }, [currentCat]);

  const filterByCategory = (category) => {
    setCurrentItems(items.filter((item) => item.category === category));
  };

  return (
    <>
      <div className="secondary-padding">
        <SelectionBar
          currentCategory={currentCat}
          setCurrentCategory={setCurrentCat}
          items={selectionBarItems}
          lightTheme
        />
      </div>
      <div className="our-team-desktop-view">
        <TeamList items={currentCat === 0 ? items : currentItems} />
      </div>
    </>
  );
};

export default DesktopView;
